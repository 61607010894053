/*
username: test1
password: PWTest#1!
*/
import './App.css';
import * as React from "react";
import {
    BrowserRouter,
    Routes,
    Route
  } from "react-router-dom";
  import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Challenges from './pages/Challenges/Challenges';
import Challenge from './pages/Challenge/Challenge';
import CoachDashboard from './pages/CoachDashboard/CoachDashboard';

/*
const [lessons, setLessons] = useState([])
useEffect(() => {
    const getLessons = async () => {
      const lessonsFromAPI = await fetchLessons()
      setLessons(lessonsFromAPI)
    }
    getLessons()


  }, [])

*/

function App() {
    /*
    checkLogin() {
            if (null == this.token)
                return false;
    
            axios.post(apiHost + "/jwt-auth/v1/token/validate", {}, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(response => {
                if (200 != response.status) {
                    alert("Authentification error");
                    return false;
                }
                console.log(response.data)
                document.getElementById("loginform").classList.add("hidden");
                return true;
            });
        }
        fetchUserInformation() {
            document.getElementById("hello-name").innerHTML = "Hallo " + this.displayName;
        }
    
      
        logout() {
            localStorage.removeItem('jwt');
            document.getElementById("loginform").classList.remove("hidden");
            console.log("Logged out.");
        }


  if(!token) {
    return <Login/>
  }

        */
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' exact element={<Home />} />
        <Route path='/login' exact element={ <Login />} />
        <Route path='/challenges' exact element={<Challenges />} />
        <Route path='/challengeInfo/finde-deine-vision' exact element={<Challenge />} />
        <Route path='/challengeInfo/glaubenssaetze-erforschen' exact element={ <Challenge />} />
        <Route path='/coach' exact element={ <CoachDashboard />} />

      </Routes>
    </BrowserRouter>

      );
}

export default App;
