import * as React from "react";
import { Link } from "react-router-dom";

export default function ChallengeEntry ({title, short_description, long_description, slug}) {
    return(
        <Link to={`/challengeInfo/${slug}`}>
            <div className="challenge_entry">
                <p className="title">{title}</p>
                <p className="description">{short_description}</p>
            </div>
        </Link>
    );
}