import * as React from "react";
import useState from "react";
import PropTypes from 'prop-types';
import useToken from './useToken';
const apiHost = "https://cachingapp-wp.lbf.one/";

async function loginUser(credentials, apiHost) {
 return fetch(apiHost + "/jwt-auth/v1/token", {
   method: 'POST',
   headers: {
     'Content-Type': 'application/json'
   },
   body: JSON.stringify(credentials)
 })
   .then(data => data.json())
}


const login = async () => {
  const res = await fetch(apiHost + "/jwt-auth/v1/token", {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      username: document.getElementById("username").value,
      password:  document.getElementById("password").value
    }),
  })

  console.log(res)
  const response = await res.json()
  console.log(response)

  if (200 != res.status) {
      alert(response.message)
      return;
  }
  
  console.log(response)
  let token = response.token
  localStorage.setItem('jwt', token)

  let displayName = response.user_display_name
}


export default function Login() {
  const {token, setToken } = useToken();
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    },
    apiHost);
    setToken(token);
  }
    return (
            <div id="loginform">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <input type="text" onChange={e => setUserName(e.target.value)} />
                    <input type="password" onChange={e => setPassword(e.target.value)} />
                    <button type="submit">Submit</button>
                </form>
            </div>
    )
}