import * as React from "react";
import "./challenges.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Header from "../../components/Header/Header";
import ChallengeEntry from "./ChallengeEntry";
import Title from "../../components/Title/Title";


class ChallengeInfo {
    constructor (title, short_description, slug) {
        this.title = title;
        this.short_description = short_description;
        this.slug = slug;
    }
}

export default function Challenges() {
    const challenges = new Array;
    challenges.push(new ChallengeInfo ("Finde deine Vision", "Eine spannende Reise zu deiner ganz eigenen Vision.", "finde-deine-vision"));
    challenges.push(new ChallengeInfo ("Erforsche deine Glaubenssätze", "Was glaubst du über das Leben? Was hältst du für möglich?", "glaubenssaetze-erforschen"));
    challenges.push(new ChallengeInfo ("Hinterfrage hinderliche Glaubenssätze", "Schließe ab mit blockierenden Gedanken.", "glaubenssaetze-hinterfragen"));

    return (
        <div className="page_challenges mobile_only">
            <Title title="Challenges"/>
            <Breadcrumbs />
            <div className="challenge_list">
                {challenges.map(function (challenge) {
                        return (
                            <ChallengeEntry title={challenge.title} short_description={challenge.short_description} slug={challenge.slug} />
                        )
                    })}
            </div>
        </div> 
    );
}