import * as React from "react";
import "./challenge.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Header from "../../components/Header/Header";
import Title from "../../components/Title/Title";


class ChallengeContent {
    constructor (title, short_description, slug) {
        this.title = title;
        this.short_description = short_description;
        this.slug = slug;
    }
}

export default function Challenge () {
    const challenge1 = new ChallengeContent ("Finde deine Vision", "Eine spannende Reise zu deiner ganz eigenen Vision.", "finde-deine-vision")

    return (
        <div className="page_challenge mobile_only">
            <Title title="Challenge …"/>
            <Breadcrumbs />
            <p>Challenge goes here…</p>
        </div> 
    );
}