import * as React from "react";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Header from '../../components/Header/Header';
import Button from "../../components/Button/Button";

const apiHost = "https://cachingapp-wp.lbf.one/";

  // Fetch Lessons
  const fetchLessons = async () => {
    const res = await fetch(apiHost + "/wp/v2/lesson")
    const data = await res.json()
    console.log(data)
    return data
  }
  
export default function Home() {

    return (
        <div className="page_home mobile_only">
          <Header />
          <Breadcrumbs />
          <div className="welcome_screen">
            <h2>Hallo Lukas,</h2>
            <p>Lust auf eine Challenge?</p>
            <Button href="/challenges" label="Zu den Challenges"  />
          </div>
        </div>
    );
}