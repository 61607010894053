import * as React from "react";
import {
    Link,
    useLocation
} from 'react-router-dom'
import "./breadcrumbs.css";

export default function Breadcrumbs() {
    const location = useLocation();

    //            if(location.pathname.startsWith("/challenge")){

    return (
        <nav className = "breadcrumbs_navigation">
            <Link to="/" className={location.pathname === "/" ? "breadcrumb-active" : "breadcrumb-not-active"}>Home</Link>
            {location.pathname.startsWith("/challenges") && (
                <>
                    <span className="breadcrumb-arrow"> &gt; </span>
                    <Link to="/challenges" className={location.pathname.startsWith("/challenges") ? "breadcrumb-active" : "breadcrumb-not-active"}>Challenges</Link>
                </>
            )}
            {location.pathname.startsWith("/challenge/") && (
                <>
                    <span className="breadcrumb-arrow"> &gt; </span>
                    <Link to="/challenges" className={location.pathname.startsWith("/challenges") ? "breadcrumb-active" : "breadcrumb-not-active"}>Challenges</Link>
                    <span className="breadcrumb-arrow"> &gt; </span>
                    <Link to="/challenges" className={location.pathname.startsWith("/challenges") ? "breadcrumb-active" : "breadcrumb-not-active"}>Challenge name</Link>
                </>
            )}
        </nav>
    );
}