import * as React from "react";
import "./coachDashboard.css";
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs";
import Title from "../../components/Title/Title";

export default function CoachDashboard() {
    return (
        <div className="page_coach_dashboard">
            <Title title="Coach Dashboard"/>
            <Breadcrumbs />
            <h2>Cochee: Lukas Ferger</h2>
            <h3>Positive Glaubenssätze</h3>
            <ul>
                <li>
                    Ich bin toll.
                </li>
                <li>
                    Ich mag mich sehr.
                </li>
                <li>
                    Ich kann alles erreichen.
                </li>
                <li>
                    <input type="text"/><button>+</button>
                </li>
            </ul>
            <h3>Negative Glaubenssätze</h3>
            <ul>
                <li>
                    Ich bin doof.
                </li>
                <li>
                    Ich mag mich manchmal nicht.
                </li>
                <li>
                    Ich kann dies und jenes erreichen.
                </li>
                <li>
                    <input type="text"/><button>+</button>
                </li>
            </ul>
        </div> 
    );
}