import * as React from "react";
import PropTypes from "prop-types";
import { useNavigate, useLocation} from "react-router-dom";

import "./title.css";

export default function Title ({title}) {
    let navigate = useNavigate();
    const location = useLocation();

    return (
        <header className="app-header-subpage">
            {location.pathname !== "/" && (
                <span className="subpage-back-button" onClick={() => navigate(-1)}>&lt;</span>
            )
            }
        <h2>{title}</h2>
        </header>
    )
}

Title.propTypes = {
    title: PropTypes.string.isRequired
};