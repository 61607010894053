import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken.token
  };

  const [token, setToken] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken.token);
  };

  return {
    setToken: saveToken,
    token
  }
}

/*
 const checkUser = async () => {
            if (null == localStorage.setItem('jwt', token))
                return false;
    
            axios.post(apiHost + "/jwt-auth/v1/token/validate", {}, {
                headers: {
                    Authorization: `Bearer ${this.token}`
                }
            }).then(response => {
                if (200 != response.status) {
                    alert("Authentification error");
                    return false;
                }
                console.log(response.data)
                document.getElementById("loginform").classList.add("hidden");
                return true;
            });
        }
        */